<template>
  <div>
    <template v-if="role == 'trader'">
      <show-trader @reload="fetch" />
    </template>
    <template v-if="role == 'moderator'">
      <show-moderator @reload="fetch" />
    </template>
    <template v-if="role == 'guest' && subscribed">
      <show-signals-user @reload="fetch" />
    </template>
    <template v-if="role == 'guest' && ! subscribed">
      <show-guest @reload="fetch" />
    </template>
    <template v-if="role == 'affiliate'">
      <show-affiliate @reload="fetch" />
    </template>
  </div>
</template>

<script>
import ShowTrader from './components/ShowTrader.vue'
import ShowModerator from './components/ShowModerator.vue'
import ShowSignalsUser from './components/ShowSignalsUser.vue'
import ShowGuest from './components/ShowGuest.vue'
import ShowAffiliate from './components/ShowAffiliate.vue'

export default {
  components: {
    ShowTrader, ShowModerator, ShowSignalsUser, ShowGuest, ShowAffiliate
  },

  mounted() {
    this.fetch()
  },

  data() {
    return {
      role: '',
      subscibed: ''
    }
  },

  methods: {
    fetch() {
      this.$axios.get(`/api/v1/moderator/users/${this.$route.params.id}`).then(response => {
        this.role = response.data.role
        this.subscribed = response.data.is_subscribed_to_signals
      })
    }
  }
}
</script>