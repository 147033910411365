<template>
  <div>
    <div class="page-heading">
      <h1>{{ user.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img :src="user.avatar" :alt="user.name">
              </div>
              <div class="col-md-8">
                <table class="table table-responsive table-borderless">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{{ user.phone_number }}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{{ user.status }}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{{ user.role }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <a href="#" @click.prevent="toggleSuspend" :class="`btn btn-${ user.suspended ? 'primary' : 'danger' } btn-block btn-sm`">{{ user.suspended ? 'Activate' : 'Suspend' }}</a>
        <a href="#" @click.prevent="makeTrader" :class="`btn btn-primary btn-block btn-sm`">Make Trader</a>
        <a href="#" @click.prevent="deleteUser" :class="`btn btn-danger btn-block btn-sm`">Delete Permanently</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: {}
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get(`/api/v1/moderator/moderators/${this.$route.params.id}`).then(response => {
        this.$loader.stop()
        this.user = response.data.user
      })
    },

    toggleSuspend() {
      this.$loader.start()
      this.$axios.post(`/api/v1/moderator/moderators/${this.$route.params.id}/suspend`).then(() => {
        this.$loader.stop()
        this.fetch()
      })
    },

    makeTrader() {
      this.$loader.start()
      this.$axios.post(`/api/v1/moderator/moderators/${this.$route.params.id}/demote`).then(() => {
        this.$loader.stop()
        this.$emit('reload')
      })
    },

    deleteUser() {
      this.$axios.delete(`/api/v1/moderator/moderators/${this.$route.params.id}`).then(() => {
        this.$toasted.show('User has been permanently deleted', { duration: 5000 })
        this.$router.push({ name: 'moderator.users.index' })
      })
    }
  }
}
</script>