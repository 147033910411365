<template>
  <div>
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-end">
          <div class="col-md-1">
            <img :src="user.avatar" :alt="user.name" class="avatar" />
          </div>
          <div class="col-md-5">
            <h2 class="mb-0">{{ user.name }}</h2>
            <p>Last Seen: {{ user.last_seen }}</p>
          </div>
          <div class="col-md-2">
            <p class="mb-0">Joined</p>
            <h4>{{ user.joined }}</h4>
          </div>
          <div class="col-md-2">
            <p class="mb-0">Participation</p>
            <h4>{{ user.comments }}</h4>
          </div>
          <div class="col-md-2">
            <p class="mb-0">Level</p>
            <h4>{{ user.level }}</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Full Profile</h3>
            <table class="table table-responsive table-bordered">
              <tbody>
                <tr>
                  <td>Email</td>
                  <td>{{ user.email }}</td>
                </tr>
                <tr>
                  <td>Role</td>
                  <td>{{ user.role }}</td>
                </tr>
                <tr>
                  <td>Phone Number</td>
                  <td>{{ user.phone_number }}</td>
                </tr>
                <tr>
                  <td>Academy Progress</td>
                  <td>{{ user.academy_progress }}</td>
                </tr>
                <tr>
                  <td>Level</td>
                  <td>{{ user.level }}</td>
                </tr>
                <tr>
                  <td>Status</td>
                  <td>{{ user.suspended ? "Suspended" : "Active" }}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <a
                href="#"
                @click.prevent="toggleSuspend"
                :class="
                  `btn btn-sm btn-${user.suspended ? 'primary' : 'danger'}`
                "
                >{{ user.suspended ? "Activate" : "Suspend" }}</a
              >
              <a
                href="#"
                @click.prevent="makeModerator"
                class="btn btn-sm btn-primary float-md-right mt-2"
                >Make Moderator</a
              >
            </div>
            <div class="mt-4">
              <a
                href="#"
                @click.prevent="deleteUser"
                class="btn btn-sm btn-danger"
                >Delete Permanently</a
              >
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h2>Affiliate Earnings</h2>
            <h3>Total: $ {{ user.running_balance }}</h3>
            <table class="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Country</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, i) in user.earnings" :key="`earning-${i}`">
                  <td>{{ line.name }}</td>
                  <td>{{ line.country }}</td>
                  <td>{{ line.amount }}</td>
                  <td>{{ line.date }}</td>
                </tr>
                <tr v-if="user.earnings.length == 0">
                  <td colspan="4">There are no earnings</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Membership</h3>
            <table class="table table-responsive table-bordered">
              <tr>
                <td>Joined</td>
                <td>{{ user.joined }}</td>
              </tr>
              <tr>
                <td>Membership Expiry</td>
                <td>{{ user.expiry }}</td>
              </tr>
            </table>
            <form @submit.prevent="extendMembership" class="mt-5">
              <div class="form-group">
                <label>Extend Membership</label>
                <select v-model="extend.id" class="form-control">
                  <option v-for="(plan, i) in plans" :key="`plan-${i}`" :value="plan.id">{{ plan.months }} Month{{ plan.months == 1 ? '' : 's' }}</option>
                </select>
              </div>
              <div class="form-group">
                <button class="btn btn-primary btn-sm">Save</button>
              </div>
            </form>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <p>Send a private message to {{ user.name }}</p>
            <form @submit.prevent="sendMessage">
              <div class="form-group">
                <label>Your Message</label>
                <textarea class="form-control" rows="4" placeholder="Your Message" v-model="new_message.content"></textarea>
              </div>
              <div class="form-group">
                <button class="btn btn-secondary btn-sm">Send Message</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch();
  },

  data() {
    return {
      user: { earnings: []},
      new_message: {},
      extend: { id: '' },
      plans: []
    };
  },

  methods: {
    sendMessage() {
      let data = {
        to: this.user.id,
        content: this.new_message.content,
        type: 'text'
      }

      this.$axios.post('/api/v1/trader/messages', data).then(() => {
        this.new_message = {}
        this.$toasted.show('Your message has been sent', { duration: 5000 })
      })
    },
    
    fetch() {
      this.$loader.start();
      this.$axios
        .get(`/api/v1/moderator/traders/${this.$route.params.id}`)
        .then((response) => {
          this.$loader.stop();
          this.user = response.data.user;
          this.plans = response.data.plans
        });
    },

    toggleSuspend() {
      this.$loader.start();
      this.$axios
        .post(`/api/v1/moderator/traders/${this.$route.params.id}/suspend`)
        .then(() => {
          this.$loader.stop();
          this.fetch();
        });
    },

    makeModerator() {
      this.$loader.start();
      this.$axios
        .post(`/api/v1/moderator/traders/${this.$route.params.id}/promote`)
        .then(() => {
          this.$loader.stop();
          this.$emit('reload')
        });
    },

    deleteUser() {
      this.$axios.delete(`/api/v1/moderator/traders/${this.$route.params.id}`).then(() => {
        this.$toasted.show('User has been permanently deleted', { duration: 5000 })
        this.$router.push({ name: 'moderator.users.index' })
      })
    },

    extendMembership() {
      let data = {
        subscription: this.extend.id
      }

      this.$axios.post(`/api/v1/moderator/traders/${this.$route.params.id}/extend`, data).then(() => {
        this.fetch()
      })
    }
  },
};
</script>
