<template>
  <div>
    <div class="page-heading">
      <h1>{{ user.name }}</h1>
    </div>
    <div class="row">
      <div class="col-md-9">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <img :src="user.avatar" :alt="user.name">
              </div>
              <div class="col-md-8">
                <table class="table table-responsive table-borderless">
                  <tbody>
                    <tr>
                      <td>Email</td>
                      <td>{{ user.email }}</td>
                    </tr>
                    <tr>
                      <td>Phone Number</td>
                      <td>{{ user.phone_number }}</td>
                    </tr>
                    <tr>
                      <td>Status</td>
                      <td>{{ user.status }}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{{ user.role }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <h2>Affiliate Earnings</h2>
            <h3>Total: $ {{ user.running_balance }}</h3>
            <table class="table table-bordered table-responsive">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Country</th>
                  <th>Amount</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(line, i) in user.earnings" :key="`earning-${i}`">
                  <td>{{ line.name }}</td>
                  <td>{{ line.country }}</td>
                  <td>{{ line.amount }}</td>
                  <td>{{ line.date }}</td>
                </tr>
                <tr v-if="user.earnings.length == 0">
                  <td colspan="4">There are no earnings</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <a href="#" @click.prevent="deleteUser" :class="`btn btn-danger btn-block btn-sm`">Delete Permanently</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      user: { earnings: [] }
    }
  },

  methods: {
    fetch() {
      this.$loader.start()
      this.$axios.get(`/api/v1/moderator/affiliates/${this.$route.params.id}`).then(response => {
        this.$loader.stop()
        this.user = response.data.user
      })
    },

    deleteUser() {
      this.$axios.delete(`/api/v1/moderator/affiliates/${this.$route.params.id}`).then(() => {
        this.$toasted.show('User has been permanently deleted', { duration: 5000 })
        this.$router.push({ name: 'moderator.users.index' })
      })
    }
  }
}
</script>